/**=====================
  52. Order History CSS start
==========================**/
.prooduct-details-box {
  margin-bottom: 30px;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 16px;
    cursor: pointer;
  }
  .media {
    border: 1px solid $light-gray;
    width: 100%;
    position: relative;
    display: block;
    .media-body {
      padding: 15px;
    }
  }
  .rating {
    color: #ffaf45;
  }
  .price {
    font-size: 14px;
    font-weight: 600;
    span {
      margin-left: 5px;
      color: $light-font;
    }
  }
  ul.rating {
    li {
      display: inline-block;
    }
  }
  .product-name {
    h6 {
      color: $theme-body-font-color;
      font-size: 15px;
    }
  }
  .btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-size: 9px;
    padding: 2px 5px;
  }
}
.order-history {
  table{
    .input-group {
      justify-content: center;
      .form-control:disabled, .form-control[readonly]{
        max-width: 70px;
        background-color: $white;
      }
    }
  }
  .rdt_TableHeader {
    display: none;
  }
  .input-group {
    text-align: right;
    max-width: 300px;
  }
  table {
    th {
      min-width: 170px;
    }
    th,
    td {
      text-align: center;
    }
    thead {
      tr {
        th {
          vertical-align: middle;
          border-top: none;
          background-color: rgba($primary-color, 0.05);
          border: 1px solid var(--theme-default);
          padding: 15px;
          font-size: 16px;
          color: var(--theme-default);
          font-weight: 600;
        }
      }
    }
    .qty-box {
      width: 25%;
      margin: 0 auto;
      border-radius: 5px;
      overflow: hidden;
      .btn {
        padding: 8px 13px;
        font-size: 10px;
      }
      input {
        padding: 0;
        border: none;
        background-color: transparent;
        &:focus {
          box-shadow: none;
          border: none;
        }
      }
      .input-group {
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid $light-gray;
        overflow: hidden;
      }
      .btn {
        background-color: transparent;
        padding: 8px 13px;
        font-size: 10px;
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          color: $light-text;
          font-weight: 600;
          .product-name {
            a {
              font-size: 15px;
              color: $dark-gray;
            }
            .order-process {
              font-size: 13px;
              padding-left: 15px;
              .order-process-circle {
                position: relative;
                &.shipped-order {
                  &:before {
                    background-color: $success-color;
                  }
                }
                &.cancel-order {
                  &:before {
                    background-color: $danger-color;
                  }
                }
              }
            }
          }
          &.total-amount {
            color: $theme-body-font-color;
          }
        }
      }
    }
  }
  .product-name {
    h6 {
      color: $theme-body-font-color;
      margin-bottom: unset;
      font-size: 14px;
    }
  }
  .rdt_Table {
    .rdt_TableHead {
      font-weight: 600;
      .rdt_TableHeadRow {
        background-color: rgba($primary-color, 0.1);
        .rdt_TableCol {
          color: $primary-color;
          .rdt_TableCol_Sortable {
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
      h6 {
        margin-bottom: 0;
      }
    }
    .rdt_TableCell {
      padding: 12px;
    }
    .rdt_TableBody {
      .rdt_TableRow {
        &:hover {
          .rdt_TableCell {
            &:first-child {
              background-color: $table-row-hover;
            }
          }
        }
        &:nth-child(odd) {
          .rdt_TableCell {
            &:first-child {
              background-color: $light-table-color;
            }
          }
        }
      }
      .rdt_TableCell {
        font-weight: 600;
        color: $dark-gray;
        .product-name {
          a {
            font-size: 16px;
            font-weight: 600;
            color: $dark-gray;
          }
        }
        .order-process {
          font-size: 13px;
          font-weight: 600;
          color: $light-text;
          padding-left: 12px;
          margin-top: 3px;
          .order-process-circle {
            position: relative;
            &:before {
              position: absolute;
              content: "";
              width: 4px;
              height: 4px;
              border-radius: 100%;
              background-color: $dark-gray;
              left: -12px;
              top: 7px;
            }
            &.shipped-order {
              &:before {
                background-color: $success-color;
              }
            }
            &.cancel-order {
              &:before {
                background-color: $danger-color;
              }
            }
          }
        }
      }
    }
  }
}
.whishlist-main {
  > div {
    margin-bottom: -30px;
  }
}
@media only screen and (max-width: 1199px) {
  .order-history {
    table {
      width: 900px;
      overflow: auto;
    }
  }
}
@media only screen and (max-width: 767px) {
  .order-history {
    table {
      tbody {
        tr {
          td {
            .product-name {
              a {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .wishlist {
    .text-end {
      text-align: left !important;
    }
  }
}
/**=====================
  52. Order History CSS end
==========================**/