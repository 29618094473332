@import "~bootstrap-scss/bootstrap.scss";
@import "vendors/fontawesome.scss";
@import "vendors/icofont.scss";
@import "vendors/themify.scss";
@import "vendors/whether-icon.scss";
@import "vendors/flag-icon.scss";
@import "vendors/chartist/chartist";
@import "responsive.scss";
@import "animate.scss";

@import "style";

@import 'react-toastify/dist/ReactToastify.css';

@import 'animate.scss';
